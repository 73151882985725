/****** Elad Shechter's RESET *******/
/*** box sizing border-box for all elements ***/
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}
html {
  font-size: 62.5%;
}
body {
  font-family: Montserrat;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
b,
strong {
  font-weight: 500;
}
button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
p {
  margin: 0;
}
cite {
  font-style: normal;
}
fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}
span {
  display: inline-block;
}

/** OWN STYLED */
.flex {
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.margin-b-15 {
  margin-bottom: 15px;
}
.margin-r-15 {
  margin-right: 15px;
}
.margin-b-20 {
  margin-bottom: 20px;
}
.margin-t-20 {
  margin-top: 20px;
}
.margin-b-25 {
  margin-bottom: 25px;
}
.margin-r-30 {
  margin-right: 30px;
}
.margin-t-30 {
  margin-top: 30px;
}
